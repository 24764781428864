export interface UserType {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  telegramInviteLink?: string;
  access: 'PROM' | 'SITE' | 'SANDBOX';
  isDocEditor?: boolean;
  // docEditor: boolean;
}

export type SendDocumentTypeIds =
  | '986f4429-cba5-4f75-81ae-7d3b2e88edfa'
  | '361e4748-1c9e-4ea9-b379-78add82523e5'
  | '8f01615a-6873-45ab-91e4-c3aea200fa79';

export enum SendDocumentType {
  EMAIL = 'EMAIL',
  EDO = 'EDO',
  POST = 'POST',
}

export const mapSendDocumentTypeTitle: {
  [key: string]: string;
} = {
  '986f4429-cba5-4f75-81ae-7d3b2e88edfa': 'E-mail',
  '361e4748-1c9e-4ea9-b379-78add82523e5': 'ЭДО',
  '8f01615a-6873-45ab-91e4-c3aea200fa79': 'Почта',
};

export interface SendDocument {
  id: SendDocumentTypeIds;
  title: string;
  type: SendDocumentType;
  price: string;
  default: boolean;
}

export interface Client {
  id: string;
  name: string;
  reportEmail: string;
  sendDocumentType: SendDocumentTypeIds;
}

export interface SetDocTypeForm {
  clientId: string;
  sendDocumentType: SendDocumentTypeIds | string;
  reportEmail: string;
}
