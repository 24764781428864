import React, { FC } from 'react';
import classNames, { Mods } from 'shared/lib/classNames/classNames';
import './Divider.style.less';

interface DividerProps {
  diretion?: 'vertical' | 'horizontal';
  className?: string;
}

const Divider: FC<DividerProps> = (props) => {
  const { diretion = 'vertical', className } = props;
  const mods: Mods = {
    [diretion]: true,
  };
  return <span className={classNames('divider', mods, [className])} />;
};

export default Divider;
