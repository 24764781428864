import { Tooltip } from 'antd';
import React, { FC, memo, ReactNode, useRef, useState } from 'react';
import classNames, { Mods } from 'shared/lib/classNames/classNames';
import './Input.style.less';

export interface TextAreaProps
  extends Omit<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    'type' | 'onChange' | 'size'
  > {
  error?: string;
  icon?: ReactNode;
  inconTooltip?: ReactNode;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | 'large';
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea: FC<TextAreaProps> = memo((props: TextAreaProps) => {
  const {
    error,
    className,
    style,
    inconTooltip,
    icon,
    onChange,
    size = 'medium',
    ...otherProps
  } = props;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const mods: Mods = {
    focused,
    warning: Boolean(error),
  };
  return (
    <>
      <div
        className={classNames('input__wrapper', mods, [className])}
        style={{ ...style }}
      >
        <textarea
          className={`form__input ${size}`}
          ref={inputRef}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={onChange}
          rows={5}
          {...otherProps}
          style={{ height: 'auto' }}
        />
        {icon && icon}
        {icon && inconTooltip && <Tooltip title={inconTooltip}>{icon}</Tooltip>}
      </div>
      {error && <div className="form__input_error">{error}</div>}
    </>
  );
});

export default TextArea;
