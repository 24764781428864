import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'shared/config/routeConfig/ProtectedRoute';
import SEOController from 'shared/config/routeConfig/SEOController';
import { routeConfig } from 'shared/config/routeConfig/routeConfig';
import PageLoader from 'shared/ui/PageLoader/PageLoader';

const AppRouter = () => (
  <Routes>
    {Object.values(routeConfig).map(({ element, path, seo }) => {
      if (path === routeConfig.profile.path) {
        return (
          <Route element={<SEOController {...seo} />} key={path}>
            <Route element={<ProtectedRoute />} key={path}>
              <Route
                key={path}
                path={path}
                element={
                  <Suspense fallback={<PageLoader />}>{element}</Suspense>
                }
              />
            </Route>
          </Route>
        );
      }
      return (
        <Route element={<SEOController {...seo} />} key={path}>
          <Route
            key={path}
            path={path}
            element={<Suspense fallback={<PageLoader />}>{element}</Suspense>}
          />
        </Route>
      );
    })}
  </Routes>
);

export default AppRouter;
