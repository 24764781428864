import React, { FC, ReactNode, useEffect } from 'react';
import Footer from 'widgets/Footer';

import Header from 'widgets/Header';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  useEffect(() => {
    setTimeout(() => {
      document
        .querySelector('.main')
        ?.setAttribute('loaded', JSON.stringify(true));
    }, 200);
    setTimeout(() => {
      document.querySelector('.main')?.classList.add('loaded-done');
    }, 1200);
  }, []);
  return (
    <main className="main">
      <Header />
      <div className="main__content">{children}</div>
      <Footer />
    </main>
  );
};

export default Layout;
