import { Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import trackEvent from 'shared/lib/top100TrackEvent';
import { docsLinks } from '../model';

interface LegalDocsDropDownProps {
  changeMenuState: () => void;
}

const LegalDocsDropDown: FC<LegalDocsDropDownProps> = ({ changeMenuState }) => {
  return (
    <Dropdown
      menu={{
        items: docsLinks.map(
          (link): ItemType => ({
            key: link.to,
            label: (
              <Link
                to={link.to}
                onClick={() => {
                  trackEvent(link.trackEvent);
                  changeMenuState();
                }}
              >
                {link.linkText}
              </Link>
            ),
          }),
        ),
      }}
    >
      <Link to="#" className="header-link">
        Правовые документы
      </Link>
    </Dropdown>
  );
};

export default LegalDocsDropDown;
