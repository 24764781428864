import React from 'react';
import InternalInput, { InputProps } from './Input';
import Password from './Password';
import TextArea from './TextArea';
import Upload from './Upload';

type CompoundedComponent = React.ForwardRefExoticComponent<InputProps> & {
  Password: typeof Password;
  Upload: typeof Upload;
  TextArea: typeof TextArea;
};

const Input = InternalInput as CompoundedComponent;

Input.Password = Password;
Input.Upload = Upload;
Input.TextArea = TextArea;

export default Input;
