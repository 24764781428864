import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { FC, memo, useCallback, useState } from 'react';
import classNames, { Mods } from 'shared/lib/classNames/classNames';
import { InputProps } from './Input';
import './Input.style.less';

export type PasswordProps = Omit<InputProps, 'type' | 'icon'>;

const Password: FC<PasswordProps> = memo((props: PasswordProps) => {
  const {
    error,
    className,
    style,
    onChange,
    inconTooltip,
    size = 'medium',
    ...otherProps
  } = props;
  const [focused, setFocused] = useState<boolean>(false);
  const [inputType, setInputType] = useState<'text' | 'password'>('password');
  const mods: Mods = {
    focused,
    warning: Boolean(error),
  };
  const getPassInputIcon = useCallback(
    (currentType: 'text' | 'password') => {
      const passIcon =
        currentType === 'password' ? (
          <EyeInvisibleOutlined
            className="passIcon"
            style={{ fontSize: '15rem', height: '100%' }}
            onClick={() => setInputType('text')}
          />
        ) : (
          <EyeOutlined
            className="passIcon"
            style={{ fontSize: '15rem' }}
            onClick={() => setInputType('password')}
          />
        );
      if (inconTooltip) {
        return <Tooltip title={inconTooltip}>{passIcon}</Tooltip>;
      }
      return passIcon;
    },
    [inconTooltip],
  );
  return (
    <>
      <div
        className={classNames('input__wrapper', mods, [className])}
        style={{ ...style }}
      >
        <input
          className={`form__input ${size}`}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={onChange}
          type={inputType}
          {...otherProps}
        />
        {getPassInputIcon(inputType)}
      </div>
      {error && <div className="form__input_error">{error}</div>}
    </>
  );
});

export default Password;
