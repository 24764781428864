import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

interface SEOControllerProps {
  metaTitle?: string;
  metaDescription?: string;
  metaKeyWords?: string;
}

const SEOController: FC<SEOControllerProps> = (props) => {
  const { metaTitle, metaDescription, metaKeyWords } = props;
  useEffect(() => {
    if (metaTitle && metaDescription && metaKeyWords) {
      document.title = metaTitle;
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', metaDescription);
      document
        .querySelector('meta[name="keywords"]')
        ?.setAttribute('content', metaKeyWords);
    }
  }, [metaDescription, metaTitle, metaKeyWords]);
  return <Outlet />;
};

export default SEOController;
