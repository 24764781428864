import { TabPaneProps, Tabs, TabsProps } from 'antd';
import React, { FC } from 'react';
import useWindowDimensions from 'shared/lib/hooks/useWindowDimensions';
import './ORDTabs.style.less';

interface ORDTabsProps extends TabsProps {
  items: any[] & TabPaneProps;
}

const ORDTabs: FC<ORDTabsProps> = (props) => {
  const { items, defaultActiveKey = '1', ...otherProps } = props;
  const { width } = useWindowDimensions();
  return (
    <Tabs
      {...otherProps}
      data-testid="menu"
      defaultActiveKey={defaultActiveKey}
      moreIcon
      tabPosition={width >= 660 ? 'left' : 'top'}
      items={items}
    />
  );
};

export default ORDTabs;
