import { docsApi } from 'entities/Documentation/model/docsApi';
import getHeadsIds from 'entities/Documentation/model/docsModelHelper';
import { IHeads } from 'entities/Documentation/model/types';

const headsApi = docsApi.injectEndpoints({
  endpoints: (builder) => ({
    getHeadsByDocId: builder.query<IHeads[], string | undefined>({
      query: (docId) => ({
        url: `/heads/${docId}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? getHeadsIds(result, []) : ['Heads']),
    }),
    editHeadById: builder.mutation<IHeads, { id?: string; value: string }>({
      query: (args) => ({
        url: `heads/edit/${args.id}`,
        method: 'PATCH',
        body: {
          value: args.value,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Heads', id: arg.id }],
    }),
    createHead: builder.mutation<IHeads, IHeads>({
      query: (arg) => ({
        url: '/heads/create',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['Heads'],
    }),
    deleteHead: builder.mutation<void, string | undefined>({
      query: (arg) => ({
        url: `heads/delete/${arg}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Heads', id: arg }],
    }),
  }),
});

export const {
  useGetHeadsByDocIdQuery,
  useEditHeadByIdMutation,
  useCreateHeadMutation,
  useDeleteHeadMutation,
} = headsApi;
