import { Tooltip } from 'antd';
import React, { FC, memo, ReactNode, useRef, useState } from 'react';
import classNames, { Mods } from 'shared/lib/classNames/classNames';
import './Input.style.less';

export interface InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'onChange' | 'size'
  > {
  error?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  icon?: ReactNode;
  inconTooltip?: ReactNode;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | 'large';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: FC<InputProps> = memo((props: InputProps) => {
  const {
    error,
    className,
    type,
    style,
    inconTooltip,
    icon,
    onChange,
    size = 'medium',
    ...otherProps
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const mods: Mods = {
    focused,
    warning: Boolean(error),
  };
  return (
    <>
      <div
        className={classNames('input__wrapper', mods, [className])}
        style={{ ...style }}
      >
        <input
          className={`form__input ${size}`}
          ref={inputRef}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={onChange}
          type={type}
          {...otherProps}
        />
        {icon && icon}
        {icon && inconTooltip && <Tooltip title={inconTooltip}>{icon}</Tooltip>}
      </div>
      {error && <div className="form__input_error">{error}</div>}
    </>
  );
});

export default Input;
