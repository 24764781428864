import React, { CSSProperties, FC, ReactNode } from 'react';
import './Section.style.less';

interface SectionProps {
  title?: string | ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  hanim?: boolean;
  className?: string;
  testid?: string;
  id?: string;
  style?: CSSProperties;
  isH1: boolean;
}

const ORDSection: FC<SectionProps> = (props) => {
  const {
    title,
    children,
    description,
    hanim = true,
    className,
    testid,
    id,
    style,
    isH1,
  } = props;
  const getTitle = () => {
    const cssClass = hanim
      ? 'section__title section-title h-anim'
      : 'section__title section-title';
    if (isH1) {
      return <h1 className={cssClass}>{title}</h1>;
    }
    return <h2 className={cssClass}>{title}</h2>;
  };
  return (
    <section
      className={`section ${className}`}
      data-testid={testid}
      id={id}
      style={style}
    >
      <div className="section__wrapper">
        {title && getTitle()}
        <div className={hanim ? 'section__desc h-anim' : 'section__desc'}>
          {description}
        </div>
        {children}
      </div>
    </section>
  );
};

export default ORDSection;
