import { DocsRouter } from 'entities/Documentation';
import Comics from 'pages/Comics';
import NotFoundPage from 'pages/ErrorPage';
import PaymentPage from 'pages/Payment';
import { ResetPassPage } from 'pages/ResetPassPage/';

import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

const MainPage = lazy(() => import('pages/MainPage'));
const UserProfilePage = lazy(() => import('pages/UserProfile'));
const FAQPage = lazy(() => import('pages/FAQPage/ui/ORD'));
const FAQFas = lazy(() => import('pages/FAQPage/ui/FAS'));
const Price = lazy(() => import('pages/Price'));
const WebinarsPage = lazy(() => import('pages/WebinarsPage'));
const VarifyPage = lazy(() => import('pages/VerifyPage'));
const LegalDocs = lazy(() => import('pages/LegalDocs'));
const KnowledgeBase = lazy(() => import('pages/KnowledgeBase/KnowledgeBase'));
export enum AppRoutes {
  MAIN = 'main',
  PROFILE = 'profile',
  NOT_FOUND = 'not_found',
  FAQORD = 'faq-ord',
  FAQFAS = 'faq-fas',
  PRICE = 'price',
  WEBINARS = 'webinars',
  VERIFY = 'verify',
  LEGALDOCS = 'legal',
  RESETPASS = 'reset-pass',
  PAYMENT = 'payment',
  DOCUMENTATION = 'documentation',
  KNOWLEDGE = 'knowledge-base',
  COMICS = 'comics',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: '/',
  [AppRoutes.PROFILE]: '/profile',
  [AppRoutes.FAQORD]: '/faq-ord',
  [AppRoutes.FAQFAS]: '/faq-fas',
  [AppRoutes.PRICE]: '/pricing',
  [AppRoutes.WEBINARS]: '/webinars',
  [AppRoutes.VERIFY]: '/verify',
  [AppRoutes.LEGALDOCS]: '/legal/*',
  [AppRoutes.RESETPASS]: '/reset-pass',
  [AppRoutes.PAYMENT]: '/payment',
  [AppRoutes.DOCUMENTATION]: '/documentation/*',
  [AppRoutes.KNOWLEDGE]: '/knowledge-base/*',
  [AppRoutes.COMICS]: '/comics/*',
  [AppRoutes.NOT_FOUND]: '*',
};

export interface SEOData {
  metaTitle: string;
  metaDescription: string;
  metaKeyWords: string;
}

export const routeConfig: Record<AppRoutes, RouteProps & { seo?: SEOData }> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
    seo: {
      metaTitle: 'Оператор рекламных данных | ОРД от SBER ADS',
      metaDescription:
        'ОРД от SberAds - аккредитованный Оператор Рекламных Данных агрегирует сведения об интернет-рекламе, маркирует индивидуальными токенами и передаёт данные о креативах, договорах, актах и статистике в Единый реестр интернет-рекламы (ЕРИР).',
      metaKeyWords:
        'оператор рекламных данных, орд sber ads, маркировка рекламы, орд маркировка рекламы, ерир, единый реестр интернет-рекламы, маркировка рекламы в интернет',
    },
  },
  [AppRoutes.PROFILE]: {
    path: RoutePath.profile,
    element: <UserProfilePage />,
  },
  [AppRoutes.FAQORD]: {
    path: RoutePath['faq-ord'],
    element: <FAQPage />,
    seo: {
      metaTitle: 'Ответы на часто задаваемые вопросы | ОРД от SberAds',
      metaDescription:
        'В данном разделе вы найдете ответы на наиболее часто задаваемые вопросы: Что нужно маркировать? Сколько стоят услуги ОРД? Нужно ли маркировать саморекламу? И многие другие…',
      metaKeyWords:
        'оператор рекламных данных, орд sber ads, маркировка рекламы, орд маркировка рекламы, ерир, единый реестр интернет-рекламы, маркировка рекламы в интернет, как маркировать рекламу',
    },
  },
  [AppRoutes.FAQFAS]: {
    path: RoutePath['faq-fas'],
    element: <FAQFas />,
    seo: {
      metaTitle: 'Разъяснения ФАС о маркировке рекламы | ОРД от SberAds',
      metaDescription:
        'В данном разделе вы найдете разъяснения ФАС: какие виды рекламы подлежат маркировке? является ли рекламой информация, размещенная на собственном сайте? И многие другие…',
      metaKeyWords:
        'оператор рекламных данных, орд sber ads, маркировка рекламы, орд маркировка рекламы, ерир, единый реестр интернет-рекламы, маркировка рекламы в интернет, как маркировать рекламу',
    },
  },
  [AppRoutes.LEGALDOCS]: {
    path: RoutePath.legal,
    element: <LegalDocs />,
  },
  [AppRoutes.PRICE]: {
    path: RoutePath.price,
    element: <Price />,
    seo: {
      metaTitle: 'Тарифы на маркировку рекламы | ОРД от SberAds',
      metaDescription:
        'Стоимость услуг по маркировке рекламы определяются согласно тарифной сетке и объему передаваемых данных в месяц. Тарификации подлежат креативы, контрагенты, договора, акты.',
      metaKeyWords:
        'орд тарифы, стоимость маркировки рекламы, маркировка рекламы цена, маркировка рекламы тарифы',
    },
  },
  [AppRoutes.WEBINARS]: {
    path: RoutePath.webinars,
    element: <WebinarsPage />,
    seo: {
      metaTitle: 'Обучающие видео по маркировке рекламы | ОРД от SberAds',
      metaDescription:
        'Обучающие видеоуроки и вебинары по маркировке рекламы. Какие данные необходимо передавать? Как маркировать рекламу? Эти и другие вопросы в нашем видео разделе.',
      metaKeyWords:
        'маркировка рекламы видеоурок, маркировка рекламы вебинар, маркировка рекламы обучение, маркировка рекламы видео',
    },
  },
  [AppRoutes.VERIFY]: {
    path: RoutePath.verify,
    element: <VarifyPage />,
  },
  [AppRoutes.RESETPASS]: {
    path: RoutePath['reset-pass'],
    element: <ResetPassPage />,
  },
  [AppRoutes.PAYMENT]: {
    path: RoutePath.payment,
    element: <PaymentPage />,
    seo: {
      metaTitle: 'Оплата услуг и порядок расчетов | ОРД от SberAds',
      metaDescription:
        'ОРД выставляет УПД (Универсальный Передаточный Документ, объединяющий счёт-фактуру и акт выполненных работ) в течение 10 рабочих дней в следующем отчетном периоде. Отчетный период – 1 календарный месяц.',
      metaKeyWords:
        'орд оплата, орд тарифы, орд цена, орд маркировка рекламы цена, орд маркировка рекламы оплата',
    },
  },
  [AppRoutes.DOCUMENTATION]: {
    path: RoutePath.documentation,
    element: <DocsRouter />,
    seo: {
      metaTitle: 'Документация | ОРД от SberAds',
      metaDescription:
        'Чтобы быстрее разобраться с вопросами маркировки рекламы и функционалом ОРД, на нашем сайте существует блок Документации, в котором можно найти Руководство по использованию интерфейса Личного кабинета, Руководство разработчика и Руководство по API.',
      metaKeyWords:
        'оператор рекламных данных, руководство пользователя орд, руководство разработчика орд, руководство по api орд',
    },
  },
  [AppRoutes.KNOWLEDGE]: {
    path: RoutePath['knowledge-base'],
    element: <KnowledgeBase />,
    seo: {
      metaTitle: 'База знаний | ОРД от SberAds',
      metaDescription:
        'Чтобы быстрее разобраться с вопросами маркировки рекламы и функционалом ОРД, на нашем сайте существует База знаний, в которой есть раздел с разъяснениями ФАС, блок сопроводительных материалов по использованию интерфейса личного кабинета, подборка обучающих видео и записей прошедших вебинаров.',
      metaKeyWords:
        'оператор рекламных данных, обучающие видео орд, обучающие видео маркировка рекламы, база знаний орд, база знаний маркировка рекламы',
    },
  },
  [AppRoutes.COMICS]: {
    path: RoutePath.comics,
    element: <Comics />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath.not_found,
    element: <NotFoundPage />,
  },
};
