import { IHeads } from './types';

const getHeadsIds = (
  result: IHeads[],
  arr: { type: 'Heads'; id: string }[],
): { type: 'Heads'; id: string }[] => {
  if (result.length === 0) {
    return arr;
  }
  result.forEach((item) => {
    arr.push({ type: 'Heads', id: item.id as string });
    getHeadsIds(item.children, arr);
  });
  return arr;
};

export default getHeadsIds;
