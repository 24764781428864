import React, { FC, useEffect } from 'react';
import { setAllVisible } from 'shared/lib/helpers';
import { ORDCard, ORDSection } from 'shared/ui';
import EmailSendFormResetPass from '../EmailSendFormrResetPass/EmailSendFormResetPass';
import './ResetPassPage.style.less';

const ResetPassPage: FC = () => {
  useEffect(() => {
    setAllVisible();
  }, []);
  return (
    <ORDSection
      isH1
      className="reset_pass_page"
      description={
        <div className="reset_pass__form_wrapper">
          <ORDCard
            title="Введите электронную почту для сброса пароля"
            width={600}
            hoverable={false}
            style={{
              marginRight: '0px',
            }}
          >
            <EmailSendFormResetPass />
          </ORDCard>
        </div>
      }
    />
  );
};

export default ResetPassPage;
