import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 24, marginRight: 10, color: '#5F5F5F' }}
    spin
  />
);

const LoadSpin: React.FC = () => <Spin indicator={antIcon} />;

export default LoadSpin;
