import { Modal } from 'antd';
import { useAppSelector } from 'app/providers/StoreProvider/config/store';
import { Facts, IUData401 } from 'features/UserCompliant/model/types';
import FactModalConfirm from 'features/UserCompliant/ui/FactModalConfirm';
import React, { FC, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

import { useUdataQuery } from 'shared/store/api/authApi';

interface ProtectedRouteProps {
  redirectTo?: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = () => {
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const returnUrl = urlParams.get('returnUrl');
  const ifId = urlParams.get('ifId');
  const { appConfig } = useAppSelector((selector) => selector.auth);
  const { error } = useUdataQuery(
    { ifId: (ifId as string) || (appConfig?.ifId as string) },
    {
      refetchOnMountOrArgChange: true,
      skip: !appConfig,
    },
  );
  const uDataError = (error as IUData401) || undefined;
  const facts = useMemo(
    () =>
      uDataError
        ? uDataError?.data.missingFacts?.reduce((value, curr) => {
            if (curr.factTypeId === 'names') {
              value.push('firstName', 'lastName', 'phone');
              value.push(curr.factTypeId);
              return value;
            }
            value.push(curr.factTypeId);
            return value;
          }, [] as Facts[])
        : [],
    [uDataError],
  );
  useEffect(() => {
    if (uDataError?.status === 401) {
      Modal.confirm({
        title: 'Необходимо авторизоваться',
        content: 'Для доступа в профиль пользователя необходимо авторизоваться',
        onOk: () => navigate('/'),
        cancelButtonProps: { style: { display: 'none' } },
      });
    }
  }, [navigate, uDataError?.status]);
  useEffect(() => {
    if (facts?.length > 0) {
      FactModalConfirm(facts, returnUrl);
    }
  }, [facts, returnUrl]);
  return <Outlet />;
};

export default ProtectedRoute;
