import { Form, notification, Result } from 'antd';
import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { useResetPassMutation } from 'shared/store/api/authApi';

import { Button, FormField, Input } from 'shared/ui';
import * as yup from 'yup';
import IEmailForResetPassForm from '../model/types';

const EmailSendFormResetPass: FC = () => {
  const [ok, setOk] = useState<boolean>(false);
  const [resetPass] = useResetPassMutation();

  const { values, setFieldValue, handleSubmit, errors } =
    useFormik<IEmailForResetPassForm>({
      initialValues: {
        email: '',
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: yup.object<{
        [Key in keyof IEmailForResetPassForm]: yup.AnySchema;
      }>({
        email: yup.string().email('Неверный email').required(),
      }),
      onSubmit: async (body) => {
        await resetPass(body)
          .unwrap()
          .then(() => {
            setOk(true);
          })
          .catch(() => notification.error({ message: 'Что-то пошло не так' }));
      },
    });
  const onFieldValueChange =
    (field: keyof IEmailForResetPassForm) => (value: string) => {
      setFieldValue(field, value);
    };
  if (ok) {
    return (
      <Result
        status="success"
        title="Запрос на сброс пароля отправлен"
        subTitle="На указанный вами адрес электронной почты отправлено письмо с ссылкой для сброса пароля"
      />
    );
  }
  return (
    <Form layout="vertical" onSubmitCapture={handleSubmit}>
      <FormField label="Электронная почта">
        <Input
          type="text"
          placeholder="Введите электронную почту для сброса пароля"
          value={values.email as string}
          error={errors.email}
          onChange={(event) => onFieldValueChange('email')(event.target.value)}
          size="large"
          style={{
            marginBottom: '10px',
          }}
        />
      </FormField>
      <Button type="submit" theme="primary" disabled={values.email === ''}>
        Сбросить пароль
      </Button>
    </Form>
  );
};

export default EmailSendFormResetPass;
