import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const requestFormApi = createApi({
  reducerPath: 'requestFormApi',
  //   tagTypes: ['Private'],
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    requestForm: builder.mutation({
      query: (queryArg) => ({
        url: '/api/v1/requestform',
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
});

export const { useRequestFormMutation } = requestFormApi;
