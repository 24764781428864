import React, { FC, ReactNode } from 'react';
import './FormField.style.less';

interface FormFieldProps {
  label?: string;
  children: ReactNode;
  htmlFor?: string;
}

const FormField: FC<FormFieldProps> = (props) => {
  const { label, children, htmlFor } = props;
  return (
    <div className="form__field">
      <label title={label} className="form__label" htmlFor={htmlFor}>
        {label}
      </label>
      {children}
    </div>
  );
};

export default FormField;
