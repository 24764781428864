import React, { FC, useEffect, useState } from 'react';
import useWindowDimensions from 'shared/lib/hooks/useWindowDimensions';

import { setConfig } from 'shared/store/AppReducer/AuthSlice';
import { useConfigQuery, useHearbeatQuery } from 'shared/store/api/authApi';

import Layout from 'widgets/Layout';
import { AppRouter } from './providers';
import { useAppDispatch } from './providers/StoreProvider/config/store';
import './styles/index.style.less';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const [polling, setPolling] = useState<number>();
  const { width, height } = useWindowDimensions();
  const { data: config } = useConfigQuery({});
  const { data } = useHearbeatQuery(
    {},
    {
      pollingInterval: polling,
    },
  );
  useEffect(() => {
    if (data) {
      setPolling(data.next_heartbeat_delay / 1000000);
    }
  }, [data]);
  useEffect(() => {
    if (config) {
      dispatch(setConfig(config));
    }
  }, [config, dispatch]);
  return (
    <Layout>
      <section className="index" id="section_index">
        <AppRouter />
        <div className="index__decor-wrap" style={{ height }}>
          {width >= 660 ? (
            <>
              <img
                src="/assets/img/index/index-img-1.png"
                alt="decor--1"
                className="index__decor index__decor--1"
                width="928rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-2.png"
                alt="decor--2"
                className="index__decor index__decor--2"
                width="1082rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-3.png"
                alt="decor--3"
                className="index__decor index__decor--3"
                width="1127rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-4.png"
                alt="decor--4"
                className="index__decor index__decor--4"
                width="1106rem"
                height="auto"
              />
            </>
          ) : (
            <>
              <img
                src="/assets/img/index/index-img-1-mobile.png"
                alt="decor--1"
                className="index__decor index__decor--1"
                width="370rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-2-mobile.png"
                alt="decor--2"
                className="index__decor index__decor--2"
                width="375rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-3-mobile.png"
                alt="decor--3"
                className="index__decor index__decor--3"
                width="375rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-4-mobile.png"
                alt="decor--4"
                className="index__decor index__decor--4"
                width="375rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-5-mobile.png"
                alt="decor--5"
                className="index__decor index__decor--5"
                width="375rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-6-mobile.png"
                alt="decor--6"
                className="index__decor index__decor--6"
                width="375rem"
                height="auto"
              />
              <img
                src="/assets/img/index/index-img-7-mobile.png"
                alt="decor--7"
                className="index__decor index__decor--7"
                width="375rem"
                height="auto"
              />
            </>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default App;
