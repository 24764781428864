import { Checkbox, Form } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { FC } from 'react';
import { getCheckBoxText } from '../model/lib';
import { Facts } from '../model/types';

interface DocConfimCheckboxProps {
  fact: Facts;
  onChangeHandler: (value: any) => void;
  error?: string;
}

const DocConfimCheckbox: FC<DocConfimCheckboxProps> = (props) => {
  const { fact, onChangeHandler, error } = props;
  return (
    <Form.Item validateStatus={error && 'error'} help={error}>
      <Checkbox
        value={fact}
        onChange={(e: CheckboxChangeEvent) => {
          onChangeHandler(e.target.checked ? e.target.value : null);
        }}
        style={{
          fontSize: '12rem',
        }}
      >
        {getCheckBoxText(fact)}
      </Checkbox>
    </Form.Item>
  );
};

export default DocConfimCheckbox;
