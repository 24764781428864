import { lazy } from 'react';
import {
  docsApi,
  useGetDocsQuery,
  useGetDocsByIdQuery,
  useGetBlocksByHeadIdQuery,
  useCreateDocsMutation,
  useEditDocsMutation,
  useDeleteDocsMutation,
} from './model/docsApi';
import {
  useEditCodeBlockMutation,
  useCreateCodeBlockMutation,
  useDeleteCodeBlockMutation,
} from './ui/DocsComponents/Blocks/CodeBlock/codeBlockApi';
import {
  useCreateImageBlockMutation,
  useDeleteImageBlockMutation,
} from './ui/DocsComponents/Blocks/ImageBlock/imageApi';
import {
  useGetTableColsQuery,
  useGetTableRowsQuery,
  useChangeRowPositionMutation,
} from './ui/DocsComponents/Blocks/TableBlock/tableBlockApi';
import {
  useGetHeadsByDocIdQuery,
  useCreateHeadMutation,
  useDeleteHeadMutation,
  useEditHeadByIdMutation,
} from './ui/DocsComponents/Heads/headsApi';
import type { IDocsTypes, IHeads } from './model/types';

const DocsRouter = lazy(() => import('./model/router/docsRouter'));

export {
  DocsRouter,
  IDocsTypes,
  IHeads,
  docsApi,
  useEditDocsMutation,
  useDeleteDocsMutation,
  useGetDocsQuery,
  useGetDocsByIdQuery,
  useCreateDocsMutation,
  useGetHeadsByDocIdQuery,
  useCreateHeadMutation,
  useDeleteHeadMutation,
  useEditHeadByIdMutation,
  useGetBlocksByHeadIdQuery,
  useEditCodeBlockMutation,
  useCreateCodeBlockMutation,
  useDeleteCodeBlockMutation,
  useCreateImageBlockMutation,
  useDeleteImageBlockMutation,
  useGetTableColsQuery,
  useGetTableRowsQuery,
  useChangeRowPositionMutation,
};
