import { Form, notification, Typography } from 'antd';
import { useFormik } from 'formik';
import React, { FC } from 'react';
import { Button } from 'shared/ui';
import * as Yup from 'yup';
import { getRequestBoy } from '../model/lib';
import { Facts, InitialValues } from '../model/types';
import DocConfimCheckbox from './DocConfimCheckbox';
import InputsForNamesFact from './InputsForNamesFact';

interface FactsConfirmFormProps {
  facts: Facts[];
  returnUrl?: string | null;
}

const FactsConfirmForm: FC<FactsConfirmFormProps> = (props) => {
  const { facts, returnUrl } = props;
  const initialValues: InitialValues = facts.reduce(
    (acc, cur) => ({ ...acc, [cur]: null }),
    {} as InitialValues,
  );
  const { values, setValues, handleSubmit, errors } = useFormik<InitialValues>({
    initialValues,
    validationSchema: Yup.object<{ [key in Facts]: Yup.AnySchema }>(
      facts.reduce((acc, cur) => {
        if (cur === 'names' || cur === 'phone') {
          return {
            ...acc,
            [cur]:
              cur === 'phone'
                ? Yup.string()
                    .notRequired()
                    .nullable()
                    .matches(
                      /^\+[0-9]{11,15}$/gm,
                      'Указан неверный номер телефона',
                    )
                : Yup.string().notRequired().nullable(),
          };
        }
        if (cur === 'firstName' || cur === 'lastName') {
          return {
            ...acc,
            [cur]: Yup.string()
              .required('Обязательное поле')
              .nullable()
              .matches(
                /^([А-Я]{1}[а-яё]{1,50}([-–\s][А-Я]{1}([а-яё]{1,50})?)?|[A-Z]{1}[a-z]{1,50})$/gm,
                'Указано некорректное имя или фамилия',
              ),
          };
        }
        return {
          ...acc,
          [cur]: Yup.string().required('Обязательное поле').nullable(),
        };
      }, {} as { [key in Facts]: Yup.AnySchema }),
    ),
    onSubmit: (formValues) => {
      const body = getRequestBoy(formValues);
      (async () => {
        await fetch('/api/v1/setfact', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userFactTypeInfo: body }),
        })
          .then((data) => {
            if (data.status === 302) {
              window.location.replace(returnUrl || window.location.href);
            } else {
              notification.error({ message: `${data.statusText}` });
            }
          })
          .catch((error) => notification.error({ message: `${error}` }));
      })();
    },
  });
  const renderSupportError =
    facts.includes('email') ||
    facts.includes('employer-inn') ||
    facts.includes('legal-docs');
  const onChangeHandler = (field: keyof InitialValues) => (value: any) => {
    setValues({ ...values, [field]: value });
  };
  return (
    <Form onSubmitCapture={handleSubmit} layout="vertical">
      {facts.map((fact) => {
        const namesFact = fact === 'names';
        const docsConfirm =
          fact !== 'firstName' &&
          fact !== 'lastName' &&
          fact !== 'phone' &&
          fact !== 'employer-inn' &&
          fact !== 'legal-docs' &&
          fact !== 'email';
        if (namesFact) {
          return (
            <InputsForNamesFact
              onChangeHandlerFirstName={onChangeHandler('firstName')}
              onChangeHandlerLastName={onChangeHandler('lastName')}
              onChangeHandlerPhone={onChangeHandler('phone')}
              errorFirstName={errors.firstName}
              errorLastName={errors.lastName}
              errorPhone={errors.phone}
            />
          );
        }
        if (docsConfirm) {
          return (
            <DocConfimCheckbox
              fact={fact}
              onChangeHandler={onChangeHandler(fact)}
              error={errors[fact]}
            />
          );
        }
        return null;
      })}
      {renderSupportError ? (
        <Typography.Text>
          У вас недостаточно прав для доступа к интересующему вас ресурсу.{' '}
          <br />
          Пожалуйста, обратитесь в поддержку ОРД{' - '}
          <a type="email" href="mailto:info@ord-lab.ru">
            info@ord-lab.ru
          </a>
        </Typography.Text>
      ) : (
        <>
          <Button
            type="submit"
            theme="primary"
            size="medium"
            style={{ width: '100%' }}
          >
            Отправить
          </Button>
          <Typography.Text type="secondary">
            * - Обязательное поле
          </Typography.Text>
        </>
      )}
    </Form>
  );
};

export default FactsConfirmForm;
