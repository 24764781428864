import getTelegramLink from 'shared/lib/getTgLink';
import Top100Event from 'shared/types/top100';

interface LinksType {
  to: string;
  linkText: string;
  trackEvent?: Top100Event;
}

export const docsLinks: LinksType[] = [
  {
    to: '/legal/public-offer',
    linkText: 'Договор оферта',
    trackEvent: Top100Event.LEGAL_OFFER,
  },
  {
    to: '/legal/consent-pesonal-data',
    linkText: 'Согласие на обработку персональных данных',
    trackEvent: Top100Event.LEGAL_PDATA,
  },
];
export const otherNavLinks = [
  {
    to: '/pricing',
    title: 'Тарифы',
    trackEvent: Top100Event.NAV_PRICE,
  },
  {
    to: '/payment',
    title: 'Оплата',
    trackEvent: Top100Event.NAV_PAYMENT,
  },
];
export const faqLinks: LinksType[] = [
  {
    to: '/faq-ord',
    linkText: 'Разъяснения ОРД',
    trackEvent: Top100Event.BASE_HEADER_FAQ,
  },
  {
    to: '/faq-fas',
    linkText: 'Разъяснения ФАС',
    trackEvent: Top100Event.BASE_HEADER_FAS,
  },
  {
    to: '/webinars',
    linkText: 'Видеоматериалы',
    trackEvent: Top100Event.BASE_HEADER_VIDEO,
  },
  {
    to: '/documentation',
    linkText: 'Документация',
    trackEvent: Top100Event.BASE_HEADER_DOCS,
  },
  {
    to: getTelegramLink(),
    linkText: 'ORD BOT',
    trackEvent: Top100Event.BASE_HEADER_TELEGRAM,
  },
  {
    to: '/comics',
    linkText: 'Примеры',
    trackEvent: Top100Event.BASE_HEADER_COMICS,
  },
];
