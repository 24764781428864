import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface IDocs {
  text: string;
  updated: string;
}

export interface OfferResponse {
  docs: {
    offer: IDocs;
    pdata: IDocs;
    privacy: IDocs;
    termsOfUse: IDocs;
  };
}

export const offerApi = createApi({
  reducerPath: 'Offer',
  //   tagTypes: ['Private'],
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    offer: builder.query<OfferResponse, any>({
      query: () => ({
        url: '/api/v1/docs',
        method: 'GET',
      }),
    }),
  }),
});

export const { useOfferQuery } = offerApi;
