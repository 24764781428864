import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserType } from './UsersTypes';

export interface IUserSlice {
  user?: UserType;
}

const initialState: IUserSlice = {
  user: undefined,
};

const enc = (email: string, telegramInviteLink: string) => {
  document.cookie = `ordsessionf=${btoa(email)}`;
  document.cookie = `ordsessiont=${telegramInviteLink}; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=None; Secure`;
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      enc(
        action.payload.email as string,
        action.payload.telegramInviteLink as string,
      );
    },
    deleteUserData: (state) => {
      state.user = undefined;
    },
  },
});

export const { setUserData, deleteUserData } = userSlice.actions;
