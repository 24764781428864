import { AuditOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

import { Facts } from '../model/types';
import FactsConfirmForm from './FactsConfirmForm';

const FactModalConfirm = (facts: Facts[], returnUrl?: string | null) => {
  return Modal.confirm({
    title: 'Данные пользователя',
    content: <FactsConfirmForm facts={facts} returnUrl={returnUrl} />,
    onCancel: () => window.location.replace(window.location.origin),
    footer: null,
    closable: true,
    maskClosable: true,
    icon: <AuditOutlined />,
    width: 500,
  });
};

export default FactModalConfirm;
