import { Result } from 'antd';
import React, { FC, useEffect } from 'react';

import { elemVisCheck } from 'shared/lib/helpers';
import { Button, ORDSection } from 'shared/ui';
import './ErrorPage.style.less';

const ErrorPage: FC = () => {
  useEffect(() => {
    elemVisCheck();
  }, []);
  return (
    <ORDSection className="error">
      <Result
        className="handleError"
        status="500"
        title="Произошла непредвиденная ошибка"
        subTitle="Попробуйте перезагрузить страницу или обратитесь в поддержку"
        extra={
          <Button
            type="button"
            theme="outlined"
            onClick={() => location.reload()}
          >
            Перезагрузить страницу
          </Button>
        }
      />
    </ORDSection>
  );
};

export default ErrorPage;
