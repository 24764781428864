import React, { FC, useState } from 'react';
import InputMask from 'react-input-mask';
import classNames, { Mods } from 'shared/lib/classNames/classNames';
import '../Input.style.less';

interface IMaskedInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'type' | 'onChange' | 'size'
  > {
  mask: string;
  error?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | 'large';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaskedInput: FC<IMaskedInputProps> = (props) => {
  const {
    className,
    style,
    error,
    mask,
    size = 'medium',
    ...otherProps
  } = props;
  const [focused, setFocused] = useState<boolean>(false);

  const mods: Mods = {
    focused,
    warning: Boolean(error),
  };
  return (
    <div
      className={classNames('input__wrapper', mods, [className])}
      style={{ ...style }}
    >
      <InputMask
        className={`form__input ${size}`}
        mask={mask}
        {...otherProps}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
};

export default MaskedInput;
