import { isFulfilled, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';

const unauthenticatedMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    //
  }
  if (isFulfilled(action)) {
    //
  }

  return next(action);
};

export default unauthenticatedMiddleware;
