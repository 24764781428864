import { docsApi } from 'entities/Documentation';

const imageApi = docsApi.injectEndpoints({
  endpoints: (builder) => ({
    createImageBlock: builder.mutation<void, FormData>({
      query: (arg) => ({
        url: '/blocks/image-block',
        method: 'POST',
        body: arg,
      }),
      invalidatesTags: ['Blocks'],
    }),
    deleteImageBlock: builder.mutation<
      void,
      { imageBlockId: number; src: string }
    >({
      query: (arg) => ({
        url: `/blocks/image-block/${arg.imageBlockId}`,
        method: 'DELETE',
        body: {
          image: arg.src,
        },
      }),
      invalidatesTags: ['Blocks'],
    }),
  }),
});

export const { useCreateImageBlockMutation, useDeleteImageBlockMutation } =
  imageApi;
