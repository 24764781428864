import { Card } from 'antd';
import { CardProps } from 'antd/es/card';
import Meta from 'antd/es/card/Meta';
import React, { FC, ReactNode } from 'react';
import classNames, { Mods } from 'shared/lib/classNames/classNames';
import './ORDCard.style.less';

interface ORDCardProps extends CardProps {
  withMeta?: boolean;
  children?: ReactNode;
  metaTitle?: string;
  metaDescription?: ReactNode | string;
  width?: number;
  className?: string;
  style?: React.CSSProperties;
}

const ORDCard: FC<ORDCardProps> = (props) => {
  const {
    withMeta = false,
    metaTitle,
    metaDescription,
    children,
    width,
    hoverable = true,
    className,
    style,
    ...otherProps
  } = props;
  const mods: Mods = {
    hover: hoverable,
  };
  return (
    <Card
      {...otherProps}
      data-testid="card"
      style={{ width, ...style }}
      className={classNames('', mods, [className])}
      hoverable={hoverable}
    >
      {withMeta && <Meta title={metaTitle} description={metaDescription} />}
      {children}
    </Card>
  );
};

export default ORDCard;
