import { Spin } from 'antd';
import React, { FC } from 'react';
import './PageLoader.style.less';

const PageLoader: FC = () => {
  return (
    <div className="loader" data-testid="loader">
      <Spin />
    </div>
  );
};

export default PageLoader;
