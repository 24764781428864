/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  CombinedState,
  configureStore,
  Reducer,
  ReducersMapObject,
} from '@reduxjs/toolkit';

import { docsApi } from 'entities/Documentation';

import { userSlice } from 'entities/User/model/userSlice';

import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';
import { authSlice } from 'shared/store/AppReducer/AuthSlice';
import { authApi } from 'shared/store/api/authApi';
import unauthenticatedMiddleware from 'shared/store/api/errorHandler';
import { offerApi } from 'shared/store/api/offerApi';
import { requestFormApi } from 'shared/store/api/requestFormApi';
import { userProfile } from 'shared/store/api/userProfileApi';
import { StateSchema } from './StateSchema';
import createReducerManager from './reducerManager';

const createReduxStore = (initialState: StateSchema) => {
  const reducer: ReducersMapObject<StateSchema> = {
    [authSlice.name]: authSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userProfile.reducerPath]: userProfile.reducer,
    [offerApi.reducerPath]: offerApi.reducer,
    [requestFormApi.reducerPath]: requestFormApi.reducer,
    [docsApi.reducerPath]: docsApi.reducer,
  };
  const reducerManager = createReducerManager(reducer);
  const store = configureStore<StateSchema>({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
    // @ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(unauthenticatedMiddleware)
        .concat(userProfile.middleware)
        .concat(requestFormApi.middleware)
        .concat(authApi.middleware)
        .concat(offerApi.middleware)
        .concat(docsApi.middleware),
  });
  // @ts-ignore
  store.reducerManager = reducerManager;
  return store;
};

export const store = createReduxStore({} as StateSchema);

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
