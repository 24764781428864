import { useAppDispatch } from 'app/providers/StoreProvider/config/store';

import { deleteUserData, setUserData } from 'entities/User';
import { UserType } from 'entities/User/model/UsersTypes';

import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Logo from 'shared/assets/svg/logo.svg';
import './Header.style.less';

import trackEvent from 'shared/lib/top100TrackEvent';
import { login, logout } from 'shared/store/AppReducer/AuthSlice';
import { useLogoutMutation } from 'shared/store/api/authApi';
import { useUserProfileMutation } from 'shared/store/api/userProfileApi';
import Top100Event from 'shared/types/top100';

import FAQDropDown from './Dropdowns/FAQDropDowm';
import LegalDocsDropDown from './Dropdowns/LegalDocsDropDown';

const Header: FC = () => {
  // hooks
  const dispatch = useAppDispatch();
  const [getProfile, { data: userDataFetch, status }] =
    useUserProfileMutation();
  const [logoutCall] = useLogoutMutation();
  // utils
  const changeMenuState = () => {
    document.body.classList.toggle('js-burger-menu-is-open');
  };
  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (status === 'fulfilled') {
      dispatch(login());
      dispatch(setUserData(userDataFetch as UserType));
    }
    if (status === 'rejected') {
      logoutCall({});
      dispatch(logout());
      dispatch(deleteUserData());
    }
  }, [dispatch, logoutCall, status, userDataFetch]);
  return (
    <header className="header">
      <Link to="/" className="header__link">
        <Logo className="header__logo" />
      </Link>
      <nav className="header-nav">
        <Link
          to="/"
          className="header-link"
          onClick={() => {
            trackEvent(Top100Event.NAV_MAIN);
            changeMenuState();
          }}
        >
          Главная
        </Link>
        <FAQDropDown changeMenuState={changeMenuState} />
        <Link
          to="/pricing"
          className="header-link"
          onClick={() => {
            trackEvent(Top100Event.NAV_PRICE);
            changeMenuState();
          }}
        >
          Тарифы
        </Link>
        <Link
          to="/payment"
          className="header-link"
          onClick={() => {
            trackEvent(Top100Event.NAV_PAYMENT);
            changeMenuState();
          }}
        >
          Оплата
        </Link>
        <LegalDocsDropDown changeMenuState={changeMenuState} />
      </nav>
      <button
        type="button"
        className="header__burger"
        onClick={changeMenuState}
      >
        <span />
      </button>
    </header>
  );
};

export default Header;
