/* eslint-disable react/button-has-type */
import React, {
  ButtonHTMLAttributes,
  cloneElement,
  CSSProperties,
  FC,
  ReactElement,
} from 'react';
import classNames from 'shared/lib/classNames/classNames';
import LoadSpin from '../LoadSpin/LoadSpin';
import './Button.style.less';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme: 'primary' | 'secondary' | 'outlined' | 'link';
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  loading?: boolean;
  style?: CSSProperties;
  top100Attribute?: string;
  suffixIcon?: ReactElement;
}

const Button: FC<ButtonProps> = (props) => {
  const {
    theme,
    className = '',
    children,
    type,
    size = 'large',
    disabled,
    loading,
    style,
    top100Attribute,
    suffixIcon,
    ...otherProps
  } = props;
  const mods: Record<string, boolean | string | undefined> = {
    [theme]: true,
    [size]: true,
  };
  const SuffixIcon = suffixIcon
    ? cloneElement(suffixIcon, {
        className: `${suffixIcon.props.className} btn-icon-suffix`,
      })
    : undefined;
  return (
    <button
      className={classNames('btn', mods, [className])}
      type={type}
      disabled={disabled}
      data-testid="btn"
      style={style}
      data-ord-attribute={top100Attribute}
      {...otherProps}
    >
      {loading && <LoadSpin />}
      {children}
      {SuffixIcon}
    </button>
  );
};

export default Button;
