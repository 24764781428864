import { useState, useEffect, useMemo } from 'react';

const getWindowDimensions = (observerHeight?: number) => {
  const { innerWidth: width } = window;
  const { body } = document;

  const height =
    observerHeight ||
    Math.max(
      body.scrollHeight,
      body.offsetHeight,
      body.clientHeight,
      body.scrollHeight,
      body.offsetHeight,
    );

  return {
    width,
    height,
  };
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const resizeObserver = useMemo(() => {
    const newObserver = new ResizeObserver((entries) => {
      setWindowDimensions(getWindowDimensions(entries[0].target.clientHeight));
    });
    return newObserver;
  }, []);
  resizeObserver.observe(document.body);
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      resizeObserver.unobserve(document.body);
    };
  }, [resizeObserver]);

  return windowDimensions;
}
