import React, { cloneElement, FC } from 'react';
import './Plates.less';

interface PlatesProps {
  items: {
    id: number;
    icon?: JSX.Element;
    text: JSX.Element | string;
  }[];
}

const Plates: FC<PlatesProps> = ({ items }) => {
  return (
    <ul className="list h-anim">
      {items.map((item, index) => {
        const icon = item?.icon
          ? cloneElement(item.icon, {
              className: `${item.icon.props.className} plate-icon`,
            })
          : undefined;
        return (
          <li
            className={`item-wrap item-wrap--${index + 1} h-anim`}
            key={item.id}
          >
            {icon && icon}
            <p className="item-wrap-text">{item.text}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default Plates;
