import {
  Descriptions as AntDescriptions,
  DescriptionsProps as AntDescriptionsProps,
} from 'antd';
import { DescriptionsItemProps } from 'antd/es/descriptions/Item';
import React, { FC } from 'react';
import './Description.style.less';

interface DescriptionProps extends AntDescriptionsProps {
  items: DescriptionsItemProps[];
}

const Description: FC<DescriptionProps> = (props) => {
  const { items } = props;
  return (
    <AntDescriptions>
      {items.map((item) => (
        <AntDescriptions.Item {...item}>
          {item.children || 'нет данных'}
        </AntDescriptions.Item>
      ))}
    </AntDescriptions>
  );
};

export default Description;
