/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/no-unstable-nested-components */

import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import rehypeRaw from 'rehype-raw';
import ORDSection from '../Section/ORDSection';
import './Markdown.style.less';

interface MarkdownProps {
  children: string;
}

const Markdown: FC<MarkdownProps> = (props) => {
  const { children } = props;
  const rebuildTags = (): Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  > => {
    const components: Partial<
      Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
    > = {
      h4: ({ ...HTMLprops }) => (
        <h2 className="offer__title section-title h-anim" {...HTMLprops} />
      ),
      p: ({ ...HTMLprops }) => <p className="desc" {...HTMLprops} />,
      ol: ({ ...HTMLprops }) => <ol className="md_list" {...HTMLprops} />,
      ul: ({ ...HTMLprops }) => <ul className="md_list" {...HTMLprops} />,
      a: ({ ...HTMLprops }) => <a {...HTMLprops} className="markdown__link" />,
      table: ({ ...HTMLProps }) => (
        <table className="md_table" {...HTMLProps} />
      ),
      h1: ({ ...HTMLprops }) => <h1 className="offer_title" {...HTMLprops} />,
    };
    return components;
  };
  return (
    <ORDSection
      isH1={false}
      description={
        <ReactMarkdown
          children={children}
          components={rebuildTags()}
          rehypePlugins={[rehypeRaw]}
        />
      }
    />
  );
};

export default Markdown;
