import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';

import React, { FC } from 'react';
import './Select.style.less';

type SelectProps = AntSelectProps;

const Select: FC<SelectProps> = (props) => {
  const { options, ...otherProps } = props;
  const filterOption = (input: string, option: any): boolean =>
    (option!.label as unknown as string)
      ?.toLowerCase()
      ?.includes(input?.toLowerCase());
  return (
    <AntSelect
      options={options}
      {...otherProps}
      className="large"
      filterOption={filterOption}
    />
  );
};

export default Select;
