import React, { FC } from 'react';
import { FormField, Input, MaskedInput } from 'shared/ui';

interface InputsForNamesFactProps {
  onChangeHandlerFirstName: (value: any) => void;
  onChangeHandlerLastName: (value: any) => void;
  onChangeHandlerPhone: (value: any) => void;
  errorFirstName?: string;
  errorLastName?: string;
  errorPhone?: string;
}

const InputsForNamesFact: FC<InputsForNamesFactProps> = (props) => {
  const {
    onChangeHandlerFirstName,
    onChangeHandlerLastName,
    onChangeHandlerPhone,
    errorFirstName,
    errorLastName,
    errorPhone,
  } = props;
  return (
    <>
      <FormField label="Имя*">
        <Input
          placeholder="Введите имя"
          onChange={(event) => onChangeHandlerFirstName(event.target.value)}
          error={errorFirstName}
        />
      </FormField>
      <FormField label="Фамилия*">
        <Input
          placeholder="Введите фамилию"
          onChange={(event) => onChangeHandlerLastName(event.target.value)}
          error={errorLastName}
        />
      </FormField>
      <FormField label="Номер мобильного телефона">
        <MaskedInput
          placeholder="+7 (999) 999-99-99"
          mask="+7 (999) 999 99 99"
          onChange={(event) =>
            onChangeHandlerPhone(event.target.value.replace(/\s|\(|\)/gm, ''))
          }
          error={errorPhone}
        />
      </FormField>
    </>
  );
};

export default InputsForNamesFact;
