import React from 'react';
import { Facts, InitialValues } from './types';

export const getCheckBoxText = (fact: Facts) => {
  switch (fact) {
    case 'pdata-1.0':
      return (
        <div>
          Я даю{' '}
          <a href="/legal/consent-pesonal-data" target="_blank">
            cогласие на обработку персональных данных
          </a>
        </div>
      );
    case 'offer-1.0':
      return (
        <div>
          Я согласен(-на) с{' '}
          <a href="/legal/public-offer" target="_blank">
            договором офертой
          </a>
        </div>
      );
    default:
      return 'default';
  }
};

export const getRequestBoy = (
  formValues: InitialValues,
): { factTypeId: string }[] => {
  return Object.entries(formValues).reduce((acc, [key, value]) => {
    if (key === 'names') {
      acc.push({
        factTypeId: `${key};${formValues.firstName};${formValues.lastName}`,
      });
      return acc;
    }
    if (key === 'phone') {
      acc.push({
        factTypeId: `${key};${formValues.phone}`,
      });
      return acc;
    }
    if (key === 'firstName' || key === 'lastName') {
      return acc;
    }
    acc.push({ factTypeId: value as string });
    return acc;
  }, [] as { factTypeId: string }[]);
};
