import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  Client,
  SendDocument,
  SetDocTypeForm,
  UserType,
} from 'entities/User/model/UsersTypes';
import { IChangePassFormArgs } from 'features/ChangePass/model/types';

export const userProfile = createApi({
  reducerPath: 'userProfile',
  //   tagTypes: ['Private'],
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1' }),
  endpoints: (builder) => ({
    userProfile: builder.mutation<UserType, void>({
      query: (queryArg) => ({
        url: '/profile',
        method: 'GET',
        body: queryArg,
      }),
    }),
    changePass: builder.mutation<void, IChangePassFormArgs>({
      query: (queryArg) => ({
        url: '/changePass',
        method: 'POST',
        body: queryArg,
      }),
    }),
    sendDocTypes: builder.query<{ sendDocumentTypes: SendDocument[] }, void>({
      query: () => ({
        url: '/clients/send_documents_types',
        method: 'GET',
      }),
    }),
    getClients: builder.query<{ clients: Client[] }, void>({
      query: () => ({
        url: '/clients/billing_info',
        method: 'GET',
      }),
    }),
    setDocType: builder.mutation<void, SetDocTypeForm>({
      query: (arg) => ({
        url: '/clients/save_billing_info',
        method: 'POST',
        body: arg,
      }),
    }),
  }),
});

export const {
  useUserProfileMutation,
  useChangePassMutation,
  useSendDocTypesQuery,
  useGetClientsQuery,
  useSetDocTypeMutation,
} = userProfile;
