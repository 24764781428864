import { Result } from 'antd';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { elemVisCheck } from 'shared/lib/helpers';
import { Button, ORDSection } from 'shared/ui';
import './NotFoundPage.style.less';

const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    elemVisCheck();
  }, []);
  return (
    <ORDSection className="error" isH1>
      <Result
        className="notFound"
        status="404"
        title="404"
        subTitle="Извините, страница, которую вы посетили, не существует."
        extra={
          <Button type="button" theme="outlined" onClick={() => navigate('/')}>
            Вернуться на главную
          </Button>
        }
      />
    </ORDSection>
  );
};

export default NotFoundPage;
