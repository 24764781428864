import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  isAuth: string | boolean;
  appConfig: { ifId: string } | undefined;
}

const initialState: AuthState = {
  isAuth: localStorage.getItem('isAuth')
    ? JSON.parse(localStorage.getItem('isAuth') || 'true')
    : false,
  appConfig: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state) {
      state.isAuth = true;
      localStorage.setItem('isAuth', JSON.stringify(true));
    },
    logout(state) {
      state.isAuth = false;
      localStorage.removeItem('isAuth');
    },
    setConfig(state, actions) {
      state.appConfig = actions.payload;
    },
  },
});

export const { login, logout, setConfig } = authSlice.actions;
