import { docsApi } from 'entities/Documentation/model/docsApi';
import {
  IChangePositionArgs,
  IRows,
  ICols,
  IEditCellArgs,
  ICreateTable,
} from 'entities/Documentation/model/types';

export const tableBlockApi = docsApi.injectEndpoints({
  endpoints: (builder) => ({
    getTableCols: builder.query<ICols[], number>({
      query: (arg) => ({
        url: `/blocks/table-block/cols/${arg}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Cols' as const, id })), 'Cols']
          : ['Cols'],
    }),
    getTableRows: builder.query<IRows[], number>({
      query: (arg) => ({
        url: `/blocks/table-block/rows/${arg}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Rows' as const, id })), 'Rows']
          : ['Rows'],
    }),
    changeRowPosition: builder.mutation<void, IChangePositionArgs>({
      query: (arg) => ({
        url: '/blocks/table-block/rows/position',
        method: 'POST',
        body: {
          tableId: arg.tableId,
          rowSet: arg.rowSet,
          direction: arg.direction,
          rowId: arg.rowId,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Rows', id: arg.rowId },
      ],
    }),
    editCellContent: builder.mutation<void, IEditCellArgs>({
      query: (arg) => ({
        url: `/blocks/table-block/rows/edit/${arg.rowContentId}`,
        method: 'PATCH',
        body: {
          value: arg.value,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Rows', id: arg.rowId },
      ],
    }),
    editColumnHeader: builder.mutation<
      void,
      { id: number; value: string; width: number }
    >({
      query: (arg) => ({
        url: `/blocks/table-block/cols/edit/${arg.id}`,
        method: 'PATCH',
        body: {
          value: arg.value,
          width: arg.width,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Cols', id: arg.id }],
    }),
    createRow: builder.mutation({
      query: (arg) => ({
        url: '/blocks/table-block/rows/create',
        method: 'POST',
        body: {
          tableId: arg.tableId,
          rowSet: arg.rowSet,
        },
      }),
      invalidatesTags: ['Rows'],
    }),
    deleteRow: builder.mutation({
      query: (arg) => ({
        url: '/blocks/table-block/rows/delete',
        method: 'DELETE',
        body: {
          rowId: arg.rowId,
          rowSet: arg.rowSet,
        },
      }),
      invalidatesTags: ['Rows'],
    }),
    createTable: builder.mutation<{ blockId: number }, ICreateTable>({
      query: (arg) => ({
        url: '/blocks/table-block',
        method: 'POST',
        body: {
          rowCount: arg.rowCount,
          colCount: arg.colCount,
          headId: arg.headId,
        },
      }),
      invalidatesTags: ['Blocks'],
    }),
    deleteTable: builder.mutation<{ blockId: number }, { tableId: number }>({
      query: (arg) => ({
        url: '/blocks/table-block',
        method: 'DELETE',
        body: {
          tableId: arg.tableId,
        },
      }),
      invalidatesTags: ['Blocks'],
    }),
    editTableTitle: builder.mutation<
      { blockId: number },
      { tableId: number; tableName: string }
    >({
      query: (arg) => ({
        url: '/blocks/table-block/title',
        method: 'PATCH',
        body: {
          tableId: arg.tableId,
          tableName: arg.tableName,
        },
      }),
      invalidatesTags: ['Blocks'],
    }),
  }),
});

export const {
  useGetTableColsQuery,
  useGetTableRowsQuery,
  useChangeRowPositionMutation,
  useEditCellContentMutation,
  useCreateRowMutation,
  useDeleteRowMutation,
  useCreateTableMutation,
  useDeleteTableMutation,
  useEditColumnHeaderMutation,
  useEditTableTitleMutation,
} = tableBlockApi;
